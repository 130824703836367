import {
	debounce,
	fetchConfig,
	ON_CHANGE_DEBOUNCE_TIMER,
	PUB_SUB_EVENTS,
	publish,
	routes,
	subscribe,
	trapFocus,
	uCoastWindow,
} from '@/scripts/setup'
import {
	closestOptional,
	closestRequired,
	qsaOptional,
	qsOptional,
	qsRequired,
	targetRequired,
	toggleActive,
	toggleIsEmpty,
} from '@/scripts/functions'
import { CartUpdate } from '@/scripts/types/events'
import { UcoastEl } from '@/scripts/core/UcoastEl'

declare let window: uCoastWindow

export type SectionsToRenderType = {
	id: string
	section: string
	selector: string
}

export class CartRemoveButton extends UcoastEl {
	static htmlSelector = 'cart-remove-button'
	constructor() {
		super()

		this.addEventListener('click', (event) => {
			event.preventDefault()
			const cartItems =
				closestOptional<CartItems>(this, 'cart-items') ||
				closestRequired<CartItems>(this, 'cart-drawer-items')
			const index = this.dataset.index
			if (!index) return
			cartItems.updateQuantity(index, 0)
		})
	}
}

export class CartItems extends UcoastEl {
	static htmlSelector = 'cart-items'
	lineItemStatusElement: HTMLElement
	cartUpdateUnsubscriber?: Function
	constructor() {
		super()
		this.lineItemStatusElement =
			qsOptional('#shopping-cart-line-item-status') ||
			qsRequired('#CartDrawer-LineItemStatus')

		const debouncedOnChange = debounce((event: Event) => {
			this.onChange(event)
		}, ON_CHANGE_DEBOUNCE_TIMER)

		this.addEventListener('change', debouncedOnChange.bind(this))
		this.cartUpdateUnsubscriber = undefined
	}

	override connectedCallback() {
		this.cartUpdateUnsubscriber = subscribe(PUB_SUB_EVENTS.cartUpdate, (event: CartUpdate) => {
			if (!(event instanceof Event)) return
			if (event.source === 'cart-items') {
				return
			}
			this.onCartUpdate()
		})
	}

	override disconnectedCallback() {
		if (this.cartUpdateUnsubscriber) {
			this.cartUpdateUnsubscriber()
		}
	}

	onChange(event: Event) {
		const target = targetRequired(event)
		const activeElement = document.activeElement
		if (!(target instanceof HTMLInputElement) || !(activeElement instanceof HTMLElement)) return
		const index = target.dataset.index
		const name = activeElement.getAttribute('name')
		if (!index || !name) return
		this.updateQuantity(index, parseInt(target.value), name)
	}

	onCartUpdate() {
		fetch(`${routes.cart_url}?section_id=main-cart-items`)
			.then((response) => response.text())
			.then((responseText) => {
				const html = new DOMParser().parseFromString(responseText, 'text/html')
				const sourceQty = html.querySelector('cart-items')
				if (!(sourceQty instanceof HTMLElement)) return
				this.innerHTML = sourceQty.innerHTML
			})
			.catch((e) => {
				console.error(e)
			})
	}

	getSectionsToRender() {
		const sections = [
			{
				id: 'cart-icon-bubble',
				section: 'cart-icon-bubble',
				selector: '.shopify-section',
			},
			{
				id: 'cart-live-region-text',
				section: 'cart-live-region-text',
				selector: '.shopify-section',
			},
		]
		const cartItemsId = document?.getElementById('main-cart-items')?.dataset.id
		if (cartItemsId) {
			sections.push({
				id: 'main-cart-items',
				section: cartItemsId,
				selector: '.js-contents',
			})
		}
		const cartFooterId = document?.getElementById('main-cart-footer')?.dataset.id
		if (cartFooterId) {
			sections.push({
				id: 'main-cart-footer',
				section: cartFooterId,
				selector: '.js-contents',
			})
		}
		return sections as SectionsToRenderType[]
	}

	updateQuantity(line: string, quantity: number, name?: string) {
		this.enableLoading(line)

		const body = JSON.stringify({
			line,
			quantity,
			sections: this.getSectionsToRender().map((section) => section.section),
			sections_url: window.location.pathname,
		})

		fetch(`${routes.cart_change_url}`, { ...fetchConfig(), ...{ body } })
			.then((response) => {
				return response.text()
			})
			.then((state) => {
				const parsedState = JSON.parse(state)
				const quantityElement =
					document.getElementById(`Quantity-${line}`) ||
					document.getElementById(`Drawer-quantity-${line}`)
				const items = document.querySelectorAll('.cart-item')
				if (!(quantityElement instanceof HTMLInputElement)) return

				if (parsedState.errors) {
					quantityElement.value = quantityElement.getAttribute('value') ?? '0'
					this.updateLiveRegions(line, parsedState.errors)
					return
				}

				toggleActive(this, parsedState.item_count === 0)

				const cartDrawerWrapper = qsOptional('cart-drawer')
				const cartFooter = document.getElementById('main-cart-footer')

				if (cartFooter) toggleIsEmpty(cartFooter, parsedState.item_count === 0)
				if (cartDrawerWrapper)
					toggleIsEmpty(cartDrawerWrapper, parsedState.item_count === 0)
				const sectionsToRender = this.getSectionsToRender()
				for (let i = 0; i < sectionsToRender.length; i++) {
					const item: SectionsToRenderType = sectionsToRender[i]
					const section = document.getElementById(item.id)
					if (!section) continue
					const elementToReplace = section.querySelector(item.selector) || section
					elementToReplace.innerHTML = this.getSectionInnerHTML(
						parsedState.sections[item.section],
						item.selector
					)
				}
				const updatedValue = parsedState.items[parseInt(line) - 1]
					? parsedState.items[parseInt(line) - 1].quantity
					: undefined
				let message = ''
				if (
					items.length === parsedState.items.length &&
					updatedValue !== parseInt(quantityElement.value)
				) {
					if (typeof updatedValue === 'undefined') {
						message = window.cartStrings.error
					} else {
						message = window.cartStrings.quantityError.replace(
							'[quantity]',
							updatedValue
						)
					}
				}
				this.updateLiveRegions(line, message)

				const lineItem =
					qsOptional(`#CartItem-${line}`) || qsOptional(`#CartDrawer-Item-${line}`)
				const nameInput = qsOptional(`[name="${name}"]`, lineItem)
				if (lineItem && nameInput) {
					cartDrawerWrapper ? trapFocus(cartDrawerWrapper, nameInput) : nameInput.focus()
				} else if (parsedState.item_count === 0 && cartDrawerWrapper) {
					trapFocus(
						qsRequired('[data-uc-drawer-empty]', cartDrawerWrapper),
						qsRequired('a', cartDrawerWrapper)
					)
				} else if (document.querySelector('.cart-item') && cartDrawerWrapper) {
					trapFocus(cartDrawerWrapper, qsOptional('.cart-item__name'))
				}
				publish(PUB_SUB_EVENTS.cartUpdate, { source: 'cart-items' })
			})
			.catch(() => {
				this.querySelectorAll('[data-uc-loading-overlay]').forEach((overlay) =>
					overlay.classList.add('hidden')
				)
				const errors = qsOptional('#cart-errors') || qsRequired('#CartDrawer-CartErrors')
				errors.textContent = window.cartStrings.error
			})
			.finally(() => {
				this.disableLoading(line)
			})
	}

	updateLiveRegions(line: string, message: string) {
		const lineItemError =
			document.getElementById(`Line-item-error-${line}`) ||
			document.getElementById(`CartDrawer-LineItemError-${line}`)
		if (lineItemError) {
			const errorText = lineItemError.querySelector('.cart-item__error-text')
			if (errorText) {
				errorText.innerHTML = message
			}
		}

		this.lineItemStatusElement.setAttribute('aria-hidden', '')

		const cartStatus =
			qsOptional('#cart-live-region-text') || qsRequired('#CartDrawer-LiveRegionText')
		cartStatus.setAttribute('aria-hidden', '')

		setTimeout(() => {
			cartStatus.setAttribute('aria-hidden', '')
		}, 1000)
	}

	getSectionInnerHTML(html: string, selector: string) {
		return (
			new DOMParser().parseFromString(html, 'text/html').querySelector(selector)?.innerHTML ??
			''
		)
	}

	enableLoading(line: string) {
		const mainCartItems =
			document.getElementById('main-cart-items') ||
			document.getElementById('CartDrawer-CartItems')
		if (mainCartItems) mainCartItems.classList.add('cart__items--disabled')

		const cartPageOverlays = qsaOptional(`#CartItem-${line} [data-uc-loading-overlay]`, this)
		if (cartPageOverlays) {
			cartPageOverlays.forEach((overlay) => overlay.classList.remove('hidden'))
		}
		const cartDrawerOverlays = qsaOptional(
			`#CartDrawer-Item-${line} [data-uc-loading-overlay]`,
			this
		)
		if (cartDrawerOverlays) {
			cartDrawerOverlays.forEach((overlay) => overlay.classList.remove('hidden'))
		}

		const activeElement = document.activeElement
		if (activeElement instanceof HTMLElement) {
			activeElement.blur()
		}

		this.lineItemStatusElement.setAttribute('aria-hidden', 'false')
	}

	disableLoading(line: string) {
		const mainCartItems =
			document.getElementById('main-cart-items') ||
			document.getElementById('CartDrawer-CartItems')
		if (mainCartItems) mainCartItems.classList.remove('cart__items--disabled')

		const cartItemElements = this.querySelectorAll(
			`#CartItem-${line} [data-uc-loading-overlay]`
		)
		const cartDrawerItemElements = this.querySelectorAll(
			`#CartDrawer-Item-${line} [data-uc-loading-overlay]`
		)

		cartItemElements.forEach((overlay) => overlay.classList.add('hidden'))
		cartDrawerItemElements.forEach((overlay) => overlay.classList.add('hidden'))
	}
}
