import { removeTrapFocus, trapFocus } from '@/scripts/setup'
import { onKeyUpEscape, setDrawerHeight } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { CartItems, SectionsToRenderType } from '@/scripts/cart/cart'
import {
	currentTargetRequired,
	qsOptional,
	qsRequired,
	qsRequiredFromDocument,
	toggleIsEmpty,
} from '@/scripts/functions'
import { SectionApiResponse } from '@/scripts/types/responses'

export class CartDrawer extends UcoastEl {
	static htmlSelector = 'cart-drawer'
	overlay: HTMLElement
	activeElement?: HTMLElement
	innerDrawer?: HTMLElement
	productId?: string
	constructor() {
		super()
		this.overlay = qsRequired('#CartDrawer-Overlay', this)
		this.innerDrawer = qsOptional('drawer__inner', this)
		this.addEventListener('keyup', (evt) => evt.code === 'Escape' && this.close())
		this.overlay.addEventListener('click', this.close.bind(this))
		this.setHeaderCartIconAccessibility()
	}

	setHeaderCartIconAccessibility() {
		const cartLink = qsRequired('#cart-icon-bubble')
		cartLink.setAttribute('role', 'button')
		cartLink.setAttribute('aria-haspopup', 'dialog')
		cartLink.addEventListener('click', (event) => {
			event.preventDefault()
			this.open(cartLink)
		})
		cartLink.addEventListener('keydown', (event) => {
			if (event.code.toUpperCase() === 'SPACE') {
				event.preventDefault()
				this.open(cartLink)
			}
		})
	}

	open(triggeredBy?: HTMLElement) {
		setDrawerHeight()
		if (triggeredBy) this.setActiveElement(triggeredBy)
		const cartDrawerNote = qsOptional('[id^="Details-"] summary', this)
		if (cartDrawerNote && !cartDrawerNote.hasAttribute('role'))
			this.setSummaryAccessibility(cartDrawerNote)
		// here the animation doesn't seem to always get triggered. A timeout seem to help
		setTimeout(() => {
			this.classList.add('animate')
			this.setAttribute('data-uc-active', '')
		})

		this.addEventListener(
			'transitionend',
			() => {
				const containerToTrapFocusOn = this.hasAttribute('data-uc-is-empty')
					? qsRequired('[data-uc-drawer-empty]', this)
					: qsRequired('#CartDrawer')
				const focusElement =
					qsOptional('.drawer__inner', this) || qsRequired('[data-uc-drawer-close]', this)
				trapFocus(containerToTrapFocusOn, focusElement)
			},
			{ once: true }
		)

		document.body.classList.add('overflow-hidden')
	}

	close() {
		this.removeAttribute('data-uc-active')
		removeTrapFocus(this.activeElement)
		document.body.classList.remove('overflow-hidden')
	}

	setSummaryAccessibility(cartDrawerNote: HTMLElement) {
		cartDrawerNote.setAttribute('role', 'button')
		cartDrawerNote.setAttribute('aria-expanded', 'false')

		if (cartDrawerNote.nextElementSibling?.getAttribute('id')) {
			cartDrawerNote.setAttribute('aria-controls', cartDrawerNote.nextElementSibling.id)
		}

		cartDrawerNote.addEventListener('click', (event) => {
			const currentTarget = currentTargetRequired(event)
			const status = !currentTarget.closest('details')?.hasAttribute('open')
				? 'true'
				: 'false'
			currentTarget.setAttribute('aria-expanded', status)
		})

		cartDrawerNote.parentElement?.addEventListener('keyup', onKeyUpEscape)
	}

	renderContents(parsedState: SectionApiResponse) {
		console.log(parsedState)
		this.innerDrawer?.hasAttribute('data-uc-is-empty') && toggleIsEmpty(this.innerDrawer, false)
		this.productId = parsedState.id // dont think this is used tbh
		this.getSectionsToRender().forEach((section) => {
			const sectionElement = section.selector
				? qsRequired(section.selector)
				: qsRequired(`#${section.id}`)
			sectionElement.innerHTML = this.getSectionInnerHTML(
				parsedState.sections[section.id],
				section.selector
			)
		})

		setTimeout(() => {
			this.overlay.addEventListener('click', this.close.bind(this))
			this.open()
		})
	}

	getSectionInnerHTML(html: string, selector = '.shopify-section') {
		return qsRequiredFromDocument(selector, new DOMParser().parseFromString(html, 'text/html'))
			.innerHTML
	}

	getSectionsToRender() {
		return [
			{
				id: 'cart-drawer',
				selector: '#CartDrawer',
			},
			{
				id: 'cart-icon-bubble',
			},
		]
	}

	getSectionDOM(html: string, selector = '.shopify-section') {
		return new DOMParser().parseFromString(html, 'text/html').querySelector(selector)
	}

	setActiveElement(element: HTMLElement) {
		this.activeElement = element
	}
}

export class CartDrawerItems extends CartItems {
	static override htmlSelector = 'cart-drawer-items'
	override getSectionsToRender(): SectionsToRenderType[] {
		return [
			{
				id: 'CartDrawer',
				section: 'cart-drawer',
				selector: '.drawer__inner',
			},
			{
				id: 'cart-icon-bubble',
				section: 'cart-icon-bubble',
				selector: '.shopify-section',
			},
		]
	}
}
