import { UcoastEl } from '@/scripts/core/UcoastEl'
import { debounce, fetchConfig, ON_CHANGE_DEBOUNCE_TIMER, routes } from '@/scripts/setup'
import { qsaRequired, qsRequired } from '@/scripts/functions'

export class CartDonation extends UcoastEl {
	static htmlSelector = 'cart-donation'
	elements: {
		toggle: HTMLButtonElement
		variantIdEl: HTMLInputElement
		radios: NodeListOf<HTMLInputElement>
	}

	variantID: string

	constructor() {
		super()
		this.elements = this.getElements()
		this.variantID = this.elements.variantIdEl.value
	}

	override connectedCallback() {
		super.connectedCallback()
		this.elements = this.getElements()
		this.variantID = this.elements.variantIdEl.value
		this.elements.toggle.removeEventListener('click', this.debouncedOnToggle.bind(this))
		this.elements.toggle.addEventListener('click', this.debouncedOnToggle.bind(this))
	}

	getElements() {
		const toggle = qsRequired<HTMLButtonElement>('[data-uc-donation-toggle]', this)
		const radios = qsaRequired<HTMLInputElement>('input[type="radio"]', this)
		const variantIdEl = qsRequired<HTMLInputElement>(
			'input[name="attributes[_donation_variant_id]"]',
			this
		)
		return {
			toggle,
			radios,
			variantIdEl,
		}
	}

	debouncedOnToggle = debounce((event: Event) => {
		event.preventDefault()
		this.onToggle(event)
	}, ON_CHANGE_DEBOUNCE_TIMER)

	onToggle(_: Event) {
		const selectedOption = qsRequired<HTMLInputElement>('input[type="radio"][checked]', this)
		const action = selectedOption.value === 'true' ? 'remove' : 'add'
		this.elements.radios.forEach((radio) => {
			if (radio.hasAttribute('checked')) {
				radio.removeAttribute('checked')
			} else {
				radio.setAttribute('checked', '')
			}
		})

		let body: string
		let path: string
		if (action === 'add') {
			this.elements.toggle.classList.add('active')
			body = JSON.stringify({
				id: this.variantID,
				quantity: 1,
				properties: { _donation: 'true' },
			})
			path = `${routes.cart_add_url}.js`
		} else {
			this.elements.toggle.classList.remove('active')
			body = JSON.stringify({ id: this.variantID, quantity: 0 })
			path = `${routes.cart_change_url}.js`
		}
		fetch(path, { ...fetchConfig(), ...{ body } })
			.then((response) => {
				return response.json()
			})
			.then((response) => this.updateDisplay(response))
			.catch((error) => {
				console.error(error)
			})
	}

	updateDisplay(response: string) {
		console.log('updateDisplay', response)
	}
}
