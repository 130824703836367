import { CartItems, CartRemoveButton } from '@/scripts/cart/cart'
import { safeDefineElement } from '@/scripts/functions'
import { initShopify } from '@/scripts/setup'
import { type uCoastWindow } from '@/scripts/setup'
import { CartDrawer, CartDrawerItems } from '@/scripts/cart/cart-drawer'
import { CartDonation } from '@/scripts/cart/cart-donation'

declare let window: uCoastWindow

function initialize() {
	// Shopify window stuff
	if (typeof window.Shopify == 'undefined') {
		window.Shopify = initShopify()
	}
	// define custom elements
	safeDefineElement(CartRemoveButton)
	safeDefineElement(CartItems)
	safeDefineElement(CartDrawer)
	safeDefineElement(CartDrawerItems)
	safeDefineElement(CartDonation)
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize)
} else {
	initialize()
}

export const shopify = window.Shopify
